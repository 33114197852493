<template>
  <div class="pa-5">
    <v-card flat>
      <v-row class="mt-4 ml-4" id="input-custom">
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
          <p class="ma-1 subtitle-2 font-weight-regular headline-color">
            Nama Pegawai
          </p>
          <p class="ma-1 subtitle-2 font-weight-bold headline-color">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="personal.nama"
            ></v-text-field>
          </p>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
          <p class="ma-1 subtitle-2 font-weight-regular headline-color">
            NIP
          </p>
          <v-card-actions class="pa-0">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="personal.NIP"
              class="mr-2"
              :disabled="generatedNIP"
            />
            <v-btn small outlined color="#717171" class="px-2" @click="checkNIP"
              >Cek NIP</v-btn
            >
            <v-btn
              small
              outlined
              :color="generatedNIP ? 'error' : '#717171'"
              class="px-2"
              @click="generateNIP"
              >{{ generatedNIP ? "Batal Generate" : "Generate NIP" }}</v-btn
            >
          </v-card-actions>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="$router.back()"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              @click="handleSubmit"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-tabs show-arrows height="50" v-model="tab" id="tab-data">
            <v-tab
              v-for="(item, i) in tabs"
              v-bind:key="i"
              class="mr-2 px-5 text-upppercase"
              >{{ item.text }}</v-tab
            >
          </v-tabs>
          <v-tabs-items>
            <transition name="slide-fade" mode="out-in">
              <keep-alive>
                <component
                  :is="tabs[tab].component"
                  :generatedNIP="generatedNIP"
                  :formDisabled="formDisabled"
                />
              </keep-alive>
            </transition>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import PegawaiService from "@/services/resources/pegawai.service";
import { mapEmployeeRootField } from "@/store/helpers";
import { mapGetters } from "vuex";
import { ACTION_ACCESS } from "@/common/constant";

// Tab
const DataPribadi = () => import("./Tab/DataPribadi");
const DataKepegawaian = () => import("./Tab/DataKepegawaian");

export default {
  components: {
    DataPribadi,
    DataKepegawaian
  },
  data() {
    return {
      formDisabled: true,
      formLoading: false,
      checkLoading: false,
      generatedNIP: false,
      tab: 0,
      tabs: [
        {
          id: "data-pribadi",
          text: `Data Pribadi`,
          icon: "",
          component: "DataPribadi"
        },
        {
          id: "data-kepegawaian",
          text: `Data Kepegawaian`,
          icon: "",
          component: "DataKepegawaian"
        }
      ]
    };
  },
  computed: {
    ...mapEmployeeRootField({
      personal: "personal",
      employee: "employee",
      keluarga: "keluarga",
      unit_kerja: "unit_kerja",
      riwayat: "riwayat"
    }),
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    isEditPegawai() {
      return ACTION_ACCESS.PEGAWAI_PRESENSI.EDIT_PEGAWAI.find(
        d => d == this.currentUser.role
      );
    }
  },
  methods: {
    addNumber(number) {
      number = String(number);
      if (number.length == 1) {
        return `0${number}`;
      }
      return `${number}`;
    },
    handleSubmit() {
      let personal = this.$_.cloneDeep(this.personal);
      personal.pendidikan.jenjang = personal.pendidikan.jenjang?.kode_pend;
      personal.pendidikan.bidang_studi =
        personal.pendidikan.bidang_studi?.kode_studi;
      personal.rt_rw = personal.rt + "/" + personal.rw;
      personal.kelurahan = personal.kelurahan?.kode_wilayah;
      personal.kecamatan = personal.kecamatan?.kode_wilayah;
      personal.kabupaten = personal.kabupaten?.kode_wilayah;
      personal.provinsi = personal.provinsi?.kode_wilayah;
      personal.kelamin = personal.kelamin == "Pria" ? 1 : 2;
      let employee = this.$_.cloneDeep(this.employee);
      employee.jabatan_eselon.jabatan =
        employee.jabatan_eselon.jabatan?.jabatan_id;
      employee.jabatan_eselon.eselon =
        employee.jabatan_eselon.eselon?.eselon_id;
      employee.golongan_pangkat.gol = employee.golongan_pangkat.gol?.gol;
      employee.golongan_pangkat.pangkat =
        employee.golongan_pangkat.pangkat?.pangkat_id;
      let unit_kerja = this.$_.cloneDeep(this.unit_kerja);
      let payload = {
        action: 1,
        personal,
        employee: {
          ...employee,
          unit_kerja: {
            unit_utama_id: unit_kerja.unit_utama?.unit_utama_id,
            unit_kerja_2_id: unit_kerja.unit_kerja_2?.unit_kerja_2_id,
            unit_kerja_3_id: unit_kerja.unit_kerja_3?.unit_kerja_3_id,
            unit_kerja_4_id: unit_kerja.unit_kerja_4
              ? unit_kerja.unit_kerja_4.unit_kerja_4_id
              : null
          }
        }
      };
      this.$confirm({
        title: "Konfirmasi",
        message: `Apakah Anda yakin akan menambah pegawai ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            this.savePegawai(payload);
          }
        }
      });
    },
    generateNIP() {
      const personal = this.$_.cloneDeep(this.personal);
      const employee = this.$_.cloneDeep(this.employee);
      const NIP = personal.NIP;
      if (NIP.length === 18) {
        const yearDoB = NIP.substring(0, 4);
        const monthDoB = NIP.substring(4, 6);
        const dayDoB = NIP.substring(6, 8);
        const tanggal_lahir = `${yearDoB}-${monthDoB}-${dayDoB}`;

        const yearTMTCPNS = NIP.substring(8, 12);
        const monthTMTCPNS = NIP.substring(12, 14);
        const tgl_mulai_CPNS = `${yearTMTCPNS}-${monthTMTCPNS}-01`;

        const kelamin = NIP.substring(14, 15);
        // const no_urut = NIP.substring(15, 18);

        this.personal = this.$_.merge(this.personal, {
          ...personal,
          tanggal_lahir,
          kelamin
        });
        this.employee = this.$_.merge(this.employee, {
          ...employee,
          cpns: {
            ...employee.cpns,
            tgl_mulai_CPNS
          },
          jabatan_eselon: {
            ...employee.jabatan_eselon,
            tmt_jabatan: tgl_mulai_CPNS
          },
          golongan_pangkat: {
            ...employee.golongan_pangkat,
            tmt_gol: tgl_mulai_CPNS
          }
        });
        this.generatedNIP = !this.generatedNIP;
      } else {
        this.$store.commit("snackbar/setSnack", {
          show: true,
          message: "NIP harus 18 karakter",
          color: "error"
        });
      }
    },
    // Service
    async savePegawai(data) {
      try {
        this.formLoading = true;
        await PegawaiService.create(data)
          .then(response => {
            const { status, message, error } = response.data;
            if (status) {
              this.$router.back();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              if (Object.entries(error).length > 0) {
                this.$store.commit("snackbar/setSnack", {
                  show: true,
                  message: error,
                  color: "error"
                });
              } else {
                this.$store.commit("snackbar/setSnack", {
                  show: true,
                  message: message,
                  color: "error"
                });
              }
            }
          })
          .catch(error => {
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message: "Data pegawai gagal ditambah",
              color: "error"
            });
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async checkNIP() {
      try {
        this.checkLoading = true;
        await PegawaiService.checkNIP({
          nip: this.personal.NIP
        })
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.formDisabled = true;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            } else {
              this.formDisabled = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            }
          })
          .catch(error => {
            this.formDisabled = true;
            this.$store.commit("snackbar/setSnack", {
              show: true,
              message: "Check NIP gagal",
              color: "error"
            });
            throw new Error(error);
          })
          .finally(() => (this.checkLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
<style lang="scss">
#tab-custom .v-tabs-bar {
  background-color: transparent;
}
#tab-custom .v-tab {
  background-color: #e0e0e0;
  color: #555555;
  border-radius: 15px 15px 0px 0px;
  &.v-tab--active {
    background-color: #1792e6;
    color: #ffffff;
  }
}
#tab-item-custom.v-tabs-items {
  border-top: 3px solid #1792e6;
}
#tab-data .v-tab {
  font-size: 0.675rem;
}
#input-custom .v-input {
  font-size: 0.775rem;
}
</style>
